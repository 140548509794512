.ztop1{
    z-index: 10000;
}
.cusbg{
    background-color: #939597;
    min-height:20vh;
    /* height: 30%; */
}
.fscreen{
    background-color: rgba(147, 149, 151, 0.384);
    width: 100vw;
    height: 50vh;
}
