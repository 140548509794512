
.home{
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}


.home__image{
    width: 100%;
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0.9) 50%, rgba(0,0,0,0));
    

} 
.tilehead{
  
            background-image: radial-gradient(rgb(255 255 255 / 85%), rgb(255 255 255 / 61%), rgb(255 255 255 / 0%));
       
}
