.header{
    background-color:white;
    display: flex;
    align-items: center;
    position: sticky;
    top:0;
    z-index:10000 ;
}
.header__logo{
    width: 70px;
    margin-left: 12%;
    object-fit: contain;
}
.header__searchIcon{
    background-color: rgb(173, 235, 206);
    height: inherit !important;
}
.header__search{
    display: flex;
    flex: 1;
}
.header__searchInput{

}
.header__nav{
    display: flex;
    justify-content: space-evenly;
    
}
.header__link{
    color: #6F926E;
    text-decoration: none;
}
.header__link:hover{
    color: #6F926E;
    text-decoration: none;   
}
.header__option{
    display: flex;
    flex-direction: column;
    margin: 0 10px;
}
.header__optionLineOne{
    font-size: 10px;
}
.header__optionLineTwo{
font-size: 13px;
font-weight: 800;
}
.header__optionBasket{
    display: flex;
    align-items: center;
}
.header__basketCount{
    margin:0 10px;
    
}

